<template>
  <div class="reset-user">
    <page-header :title="title" />
    <page-section group-label="" v-if="!isFinded">
      <field-section label="Entre com a URL do QR Code" slot-in>
        <template #value>
          <v-text-field outlined background-color="white" v-model="url" dense/>
          <v-btn color="primary" small @click="onSearch" :disabled="!url">
            buscar
            <v-progress-circular v-if="isLoading" indeterminate width="2" size="15" class="ml-1"/>
          </v-btn>
        </template>
      </field-section>
    </page-section>
    <page-section group-label="Dados do Usuário" v-else>
      <field-section label="E-mail" :value="user.userEmail" />
      <field-section label="Data de Criação" :value=" user.userCreatedAt ? new Date(user.userCreatedAt).toLocaleString() : null" />
      <div v-if="!nextConfirm && !resetSuccess">
        <v-btn color="white" small class="mt-5 mr-4" @click="onCancelReset">cancelar</v-btn>
        <v-btn color="primary" small class="mt-5" @click="onNextConfirmation">resetar conta</v-btn>
      </div>
      <field-section class="mt-4" v-else-if="nextConfirm" label="Para confirmar digite o e-mail do usuário" slot-in>
        <template #value>
          <v-text-field outlined background-color="white" v-model="emailConfirm" dense />
          <v-btn color="white" small class="mr-4" :disabled="isLoading" @click="onCancelConfirmation">cancelar</v-btn>
          <v-btn color="primary" small :disabled="emailConfirm !== user.userEmail" @click="onConfirmation">
            confirmar
            <v-progress-circular v-if="isLoading" indeterminate width="2" size="15" class="ml-1"/>
          </v-btn>
        </template>
      </field-section>
      <field-section class="mt-4" v-else-if="resetSuccess" slot-in>
        <template #value>
          <v-layout align-center>
            <v-icon color="green" size="18" class="mr-2">mdi-check-circle</v-icon> Conta resetada com sucesso!
          </v-layout>
          <v-btn class="mt-10" color="primary" small @click="onFinalize">finalizar</v-btn>
        </template>
      </field-section>
    </page-section>
  </div>
</template>
<script>
import PageHeader from "@/components/headers/page-header";
import PageSection from "@/components/sections/page-section";
import FieldSection from "@/components/sections/field-section";

export default {
  metaInfo: {
    titleTemplate: "Resetar Usuário QR Code | %s",
  },
  components: {
    FieldSection,
    PageSection,
    PageHeader,
  },
  data: () => ({
    title: "Resetar Usuário QR Code",
    url: '',
    emailConfirm: '',
    user: {},
    isFinded: false,
    isLoading: false,
    nextConfirm: false,
    resetSuccess: false
  }),
  mounted() {

  },
  methods: {
    onCancelReset() {
      this.isFinded = false;
      this.user = {};
    },
    onCancelConfirmation() {
      this.isFinded = false;
      this.user = {};
      this.emailConfirm = null;
      this.nextConfirm = false;
    },
    onNextConfirmation() {
      this.nextConfirm = true;
    },
    onConfirmation() {

      // impedir de duplicar a requisição caso o loading estiver ativo
      if(this.isLoading) return;

      this.isLoading = true;

      if(this.url.includes('app/pet')) {

        const uid = this.url.split('sigabrad.com.br/')[1].trim();

        this.$store.dispatch('qrcode/resetUser', {
          uid,
          type: 'pet'
        }).then(() => {
          this.isLoading = false;
          this.nextConfirm = false;
          this.resetSuccess = true;
        }).catch(err => {
          console.error(err);
          this.tryErrors(err?.response.status, err?.response.data?.message)
        })

      } else if (this.url.includes('app/id')) {

        const uid = this.url.split('sigabrad.com.br/')[1].trim();

        this.$store.dispatch('qrcode/resetUser', {
          uid,
          type: 'person'
        }).then(() => {
          this.isLoading = false;
          this.nextConfirm = false;
          this.resetSuccess = true;
        }).catch(err => {
          console.error(err);
          this.tryErrors(err?.response.status, err?.response.data?.message)
        })
      }
    },
    onFinalize() {
      this.resetSuccess = false;
      this.onCancelConfirmation();
      this.url = null;
    },
    onSearch() {

      // impedir de duplicar a requisição caso o loading estiver ativo
      if(this.isLoading) return;

      this.isFinded = false;
      this.isLoading = true;

      if(this.url.includes('app/pet')) {

        const uid = this.url.split('sigabrad.com.br/')[1];

        this.$store.dispatch('qrcode/getDataUser', {
          uid,
          type: 'pet'
        }).then(data => {
          this.user = data;
          this.isFinded = true;
          this.isLoading = false;
        }).catch(err => {
          console.error(err);
          this.tryErrors(err?.response.status, err?.response.data?.message)
        })

      } else if (this.url.includes('app/id')) {

        const uid = this.url.split('sigabrad.com.br/')[1];

        this.$store.dispatch('qrcode/getDataUser', {
          uid,
          type: 'person'
        }).then(data => {
          this.user = data;
          this.isFinded = true;
          this.isLoading = false;
        }).catch(err => {
          console.error(err);
          this.tryErrors(err?.response.status, err?.response.data?.message)
        })
      }
    },
    tryErrors(status, message) {

      this.isLoading = false;

      switch (status) {
        case 404:
          if(message.includes('userPerson')) {
            this.$store.commit("setSnackbarError", {
              message: "QR Code válido, porém nenhum usuário foi encontrado associado.",
              status: true,
            });
          } else {
            this.$store.commit("setSnackbarError", {
              message: "QR Code não encontrado na base de dados. Verifique e tente novamente.",
              status: true,
            });
          }
          break;
        case 403: case 401:
          this.$store.commit("setSnackbarError", {
            message: "Sem permissão para acessar o recurso.",
            status: true,
          });
          break;
        case 400:
          this.$store.commit("setSnackbarError", {
            message: "Erro ao enviar a solicitação, parâmetros incorretos.",
            status: true,
          });
          break;
        default:
          this.$store.commit("setSnackbarError", {
            message: "Serviço indisponível. Tente mais tarde.",
            status: true,
          });
          break;
      }
    }
  }
};
</script>

<style lang="scss">
.reset-user {

}
</style>
